import React, { useContext, useEffect, useState } from "react";
import { Flex } from "rebass";

import { GlobalContext } from "../context/GlobalContextProvider";

import CardBox from "./CardBox";
import Circle from "./Circle";

const sessionMessage = {
  sessionVal: "10,000",
};

const Cancelling = () => {
  const {
    handleCancelSubscription,
    customerDetails,
    providerPrice,
  } = useContext(GlobalContext);

  const [providerCount, setProviderCount] = useState(0);
  const [sessionPrice, setSessionPrice] = useState(0.89);

  useEffect(() => {
    if (customerDetails.number_of_providers !== undefined) {
      setProviderCount(customerDetails.number_of_providers);
    }
    if (customerDetails.sessions_balance !== undefined) {
      if (customerDetails.sessions_balance <= 1499) {
        setSessionPrice(0.89);
      } else if (
        customerDetails.sessions_balance >= 1500 &&
        customerDetails.sessions_balance <= 4999
      ) {
        setSessionPrice(0.8);
      } else if (
        customerDetails.sessions_balance >= 5000 &&
        customerDetails.sessions_balance <= 9999
      ) {
        setSessionPrice(0.76);
      } else {
        setSessionPrice(0.71);
      }
    }
  }, [customerDetails]);

  return (
    <div classNameVal="cancelling-wrapper">
      <CardBox classNameVal="cancelling">
        <h4 className="t-card-title text-center">Your subscription</h4>
        <Flex
          className="flex-wrapper"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          <div className="subscription-details">
            <React.Fragment>
              <div className="provider-item">
                <p className="t-price">
                  $
                  {customerDetails.recording_status ? (
                    <React.Fragment>
                      {Math.round(
                        (providerCount * providerPrice + 5 * providerCount) *
                          100
                      ) / 100}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {providerCount * providerPrice}
                    </React.Fragment>
                  )}
                  <span> / month</span>
                </p>
                <p className="t-note">
                  Provider fee ({providerCount} providers)
                </p>
              </div>
              {customerDetails.recording_status && (
                <>
                  <br />
                  <div className="provider-item">
                    <p className="t-price">
                      ${5.0 * customerDetails.number_of_providers}{" "}
                      <span>/ month</span>
                    </p>
                    <p className="t-note">Recording and archiving</p>
                  </div>
                </>
              )}
            </React.Fragment>
          </div>
          <Circle circleContent={sessionMessage} />
        </Flex>
        <p className="t-card-desc text-center">
          You’re about to cancel your subscription and lose <span>10 days</span>{" "}
          of paid usage for your providers and features.
        </p>
      </CardBox>
      <div className="note-holder">
        <p className="t-note text-center">
          To cancel your subscription you’ll need to pay any outstanding
          recording and archiving sessions or group sessions on your account.
          We’ll issue a refund for your unused sessions at a rate of{" "}
          <strong>${sessionPrice} per session</strong> and credit it on your
          invoice for any outstanding balances. If your credit is greater than
          the cost of the outstanding balances on your account, we’ll refund the
          difference.
        </p>
        <div className="action-holder">
          <button
            type="button"
            className="btn-blue btn-full"
            onClick={handleCancelSubscription}
          >
            Confirm cancellation
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cancelling;

class Auth {
  constructor() {
    if (typeof window !== `undefined`) {
      const sessionCareCustomerId = sessionStorage.getItem(
        "sessionCareCustomerId"
      );
      const api_key = sessionStorage.getItem("apiKey");

      if (sessionCareCustomerId && api_key) {
        sessionStorage.setItem("authenticated", true);
      }
    }
  }

  login(
    sessionCareCustomerId,
    api_key,
    subscriptionStatus,
    blockFreeTrial,
    callback
  ) {
    if (typeof window !== `undefined`) {
      sessionStorage.setItem("apiKey", api_key);
      sessionStorage.setItem("sessionCareCustomerId", sessionCareCustomerId);
      sessionStorage.setItem("subscriptionStatus", subscriptionStatus);
      sessionStorage.setItem("blockFreeTrial", blockFreeTrial);
      sessionStorage.setItem("authenticated", true);
    }

    callback();
  }

  getAuthConfig() {
    if (typeof window !== `undefined`) {
      if (sessionStorage.getItem("authenticated")) {
        return {
          headers: {
            api_key: sessionStorage.getItem("apiKey"),
          },
        };
      }
    }
  }

  get(attribute) {
    if (typeof window !== `undefined`) {
      return sessionStorage.getItem(attribute);
    }
  }

  logout() {
    if (typeof window !== `undefined`) {
      sessionStorage.clear();
    }
  }

  isAuthenticated() {
    if (typeof window !== `undefined`) {
      return sessionStorage.getItem("authenticated");
    }
  }
}

export default new Auth();

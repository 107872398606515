// Depedencies
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

// Helpers
import auth from "./Auth";
import { GlobalContext } from "../context/GlobalContextProvider";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { createAlert } = useContext(GlobalContext);

  return (
    <React.Fragment>
      <Route
        {...rest}
        render={(props) => {
          if (!auth.isAuthenticated()) {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            if (auth.get("authenticated") === "true") {
              if (auth.get("subscriptionStatus") === "new")
                return (
                  <Redirect
                    to={{
                      pathname: "/plan-selection",
                      state: {
                        from: props.location,
                      },
                    }}
                  />
                );
              else if (auth.get("subscriptionStatus") === "canceled") {
                createAlert("error", "Your account has been cancelled", "");
                return (
                  <Redirect
                    to={{
                      pathname: "/error",
                      state: {
                        from: props.location,
                      },
                    }}
                  />
                );
              } else return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          }
        }}
      />
    </React.Fragment>
  );
};

export default ProtectedRoute;

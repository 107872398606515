// import './App.css';
import "./styles/main.scss";

import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import { Helmet } from "react-helmet";

import AuthRoute from "./helpers/AuthRoute";
import PlanRoute from "./helpers/PlanRoute";
import ProtectedRoute from "./helpers/ProtectedRoute";
import ThankyouRoute from "./helpers/ThankyouRoute";

import PlanSelection from "./containers/plan-selections";
import Home from "./containers/Home";
import SuccessfulMessage from "./containers/thank-you";
import Session from "./containers/session";
import AddMoreSession from "./containers/AddSessionPage";
import SessionRefill from "./containers/session-refill";
import Providers from "./containers/providers";
import CancelSubscription from "./containers/cancel-subscription";
import Loading from "./containers/loading";
import Cancellation from "./containers/cancellation";
import NoRouteMatch from "./containers/404";
import Verification from "./containers/VerificationComponent";
import ErrorPage from "./containers/ErrorPage";

import GlobalContextProvider from "./context/GlobalContextProvider";

function App() {
  return (
    <Router history={history}>
      <GlobalContextProvider>
        <div className="App">
          <Helmet>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-9ECQC28X1Y"
            ></script>
            <script>
              {
                "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-9ECQC28X1Y');"
              }
            </script>
          </Helmet>
          <div className="custom-container">
            <div className="app-wrapper">
              <Switch>
                <AuthRoute path={["/", "/home"]} exact component={Home} />
                <PlanRoute
                  path={["/plan-selections", "/plan-selection"]}
                  component={PlanSelection}
                />

                <ThankyouRoute
                  path={["/thanks", "/thankyou"]}
                  component={SuccessfulMessage}
                />
                <ProtectedRoute path="/session" component={Session} />
                <ProtectedRoute
                  path="/add-session"
                  component={AddMoreSession}
                />
                <ProtectedRoute
                  path="/session-refill"
                  component={SessionRefill}
                />
                <ProtectedRoute path="/providers" component={Providers} />
                <ProtectedRoute
                  path="/cancel-subscription"
                  component={CancelSubscription}
                />
                <Route path="/loading" component={Loading} />
                <Route path="/cancellation" component={Cancellation} />
                <Route path="/error" component={ErrorPage} />
                <Route path="/404" component={NoRouteMatch} />
                <Route path="/:access_key" exact component={Verification} />
              </Switch>
            </div>
          </div>
        </div>
      </GlobalContextProvider>
    </Router>
  );
}

export default App;

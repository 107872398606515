import React, { useContext, useEffect, useState } from "react";
import { Flex } from "rebass";
import { Link } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContextProvider";
import Moment from "../components/Moment";
import moment from "moment";

const AccountRecording = ({ accountRecording }) => {
  const { customerDetails, recordingPlan } = useContext(GlobalContext);

  const [recordingUsage, setRecordingUsage] = useState(0);
  const [recordingBill, setRecordingBill] = useState(0.0);
  const [nextBilling, setNextBilling] = useState(0);
  const [recordingUsageSince, setRecordingUsageSince] = useState(0);

  useEffect(() => {
    if (customerDetails !== "") {
      setRecordingUsage(customerDetails.recordings.usage);
      setRecordingBill(
        parseFloat(
          customerDetails.recordings.usage *
            (customerDetails.recording_status ? 0.75 : 1.5)
        ).toFixed(2)
      );

      if (customerDetails.subscription_details !== null) {
        setNextBilling(customerDetails.subscription_details.nextBillingDate);
      } else {
        setNextBilling(customerDetails.next_billing_date);
      }

      let monthDifference = moment(customerDetails.next_billing_date).diff(
        customerDetails.created_on,
        "month"
      );

      if (monthDifference === 1)
        setRecordingUsageSince(customerDetails.created_on);
      else
        setRecordingUsageSince(
          moment(customerDetails.next_billing_date).subtract(1, "months")
        );
    }
  }, [customerDetails]);

  return (
    <Flex flexWrap="wrap" justifyContent="space-between" alignItems="center">
      <div className="content-holder">
        <h4 className="t-card-title">Recording & Archiving</h4>
        <p className="t-card-desc">
          {recordingPlan === 1
            ? "This feature allows any provider to record and archive any session. You’re charged $5.00 per month for each provider on your account, then $0.75 for each session that’s recorded."
            : "This feature allows any provider to record and archive any session. You’ll be charged $1.50 for each session that is recorded."}
        </p>
      </div>
      <div className="record-holder">
        <Flex className="record-info">
          <div className="record-billing text-center">
            <p className="t-val">{recordingUsage}</p>
            <p className="t-desc">recording since</p>
            <p className="t-date">
              <Moment value={recordingUsageSince} />
            </p>
          </div>
          <div className="record-billing text-center">
            <p className="t-val">${recordingBill}</p>
            <p className="t-desc">will be billed on</p>
            <p className="t-date">
              <Moment value={nextBilling} />
            </p>
          </div>
        </Flex>
        <Link to="/providers" className="btn-plain">
          Change feature preference
        </Link>
      </div>
    </Flex>
  );
};

export default AccountRecording;

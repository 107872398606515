import React, { useContext } from 'react'
import { GlobalContext } from '../context/GlobalContextProvider'

import QuantityCounterSessions from './QuantityCounterSessions'

const sessionPrices = [
  {
    text: "$0.80 / session when you buy 1,500+",
  },
  {
    text: "$0.76 / session when you buy 5,000+ ",
  },
  {
    text: "$0.71 / session when you buy 10,000+",
  },
]

const PlanSelectionSessions = () => {
  const {
    sessionPrice
  } = useContext(GlobalContext)

  return(
    <React.Fragment>
      <h3 className='title'>Sessions</h3>
      <p className='desc'>Sessions are unique encounters conducted via secure videos, voice, and chat. The session balance is shared among all providers within your account. Each session includes up to 20 minutes of live video and voice interaction for up to three participants. Unused sessions rollover and do not expire.</p>
      <div className='pricing-wrapper'>
        <div className='pricing-holder'>
          <span className='price'>${sessionPrice} </span> <span>/ session</span>
        </div>
        <div className='sub-pricing-holder'>
          <ul>
            {sessionPrices.map((sub_item, i) =>
                <li key={i}>{sub_item.text}</li>
            )}
          </ul>
        </div>
      </div>
      <QuantityCounterSessions />
    </React.Fragment>
  )
}

export default PlanSelectionSessions
import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";

import CardBox from "./CardBox";
import ChoiceTotal from "./ChoiceTotal";

const RecordingArchiving = ({ content, type }) => {
  const { recordingPlan, recordingStatus, handleRecordingPlan } = useContext(
    GlobalContext
  );

  useEffect(() => {
    // Set choice to current plan every component render
    if (recordingPlan !== recordingStatus) {
      handleRecordingPlan(recordingStatus ? 1 : 0);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <CardBox classNameVal="single-box-holder">
        <h3 className="t-card-title">{content.title}</h3>
        <p className="t-card-desc">{content.description}</p>
        <div className="form-wrapper">
          {content.options.map((sub_item, i) => (
            <label key={i} className="pricing-option">
              <span className="price">$ {sub_item.price} </span>{" "}
              <span>&nbsp;/ {sub_item.price_label} </span>
              <input
                type="radio"
                name="radio"
                checked={recordingPlan === i && "checked"}
                onChange={() => {
                  handleRecordingPlan(i);
                }}
              />
              <span className="checkmark"></span>
              {sub_item.bundle.price && (
                <div className="additional-price">
                  <span className="separator-plus">plus</span>
                  <span className="price">$0.75 </span>{" "}
                  <span>&nbsp;/ recording</span>
                  <input type="radio" name="radio" />
                </div>
              )}
              {content.options.length >= 2 && (
                <span className="separator">OR</span>
              )}
            </label>
          ))}
        </div>
      </CardBox>
      <ChoiceTotal type={type} />
    </React.Fragment>
  );
};

export default RecordingArchiving;

import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";

const QuantityCounterSessions = () => {
  const { sessionCount, setSessionCount, hasValue, setHasValue } = useContext(
    GlobalContext
  );

  const handleQuantityCounter = (e) => {
    const value = e.target.value < 25 ? 25 : e.target.value;
    setSessionCount(parseFloat(value));
  };

  useEffect(() => {
    if (sessionCount <= 0) {
      setSessionCount(25);
    } else {
      setSessionCount(parseFloat(sessionCount));
    }

    if (isNaN(sessionCount)) {
      setHasValue(true);
    } else setHasValue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionCount]);

  // || isNaN(sessionCount)

  const decrease = () => {
    setSessionCount(parseFloat(sessionCount) - 100);
  };
  const increase = () => {
    setSessionCount(parseFloat(sessionCount) + 100);
  };

  return (
    <React.Fragment>
      <div className="def-number-input number-input">
        <button
          onClick={decrease}
          className={`minus qty-button ${sessionCount < 26 && "btn-disabled"}`}
        >
          -
        </button>
        <input
          className="quantity"
          name="quantity"
          type="number"
          value={sessionCount}
          onChange={handleQuantityCounter}
        />
        <button onClick={increase} className="plus qty-button">
          +
        </button>
      </div>
      {hasValue && <span className="warning">Please input a number</span>}
    </React.Fragment>
  );
};

export default QuantityCounterSessions;

import React, { useContext } from "react";
import { Flex } from "rebass";

import Header from "../components/Header";
import CardBox from "../components/CardBox";
import QuantityCounterProviders from "../components/QuantityCounterProviders";
import RecordingArchiving from "../components/RecordingArchiving";
import { GlobalContext } from "../context/GlobalContextProvider";
import Alert from "../components/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals, faPlus } from "@fortawesome/free-solid-svg-icons";

const headerInfo = {
  back: "/session",
  heading: "Your providers",
};

const RecordingArchivingData = {
  title: "Recording & Archiving",
  description:
    "This feature allows any provider to record and archive any session. Decide whether to use this feature on an ad-hoc basis and be charged $1.50 per recorded session, or you can add our pre-payment bundle. With the bundle you’ll be charged $5.00 per month for each provider on your account, then only $0.75 per recorded session.",
  options: [
    {
      price: "1.50",
      price_label: "recording",
      bundle: {},
    },
    {
      price: "5.00",
      price_label: "provider",
      bundle: {
        price: "0.75",
        price_label: "recording",
      },
    },
  ],
};

const Providers = () => {
  const { customerDetails } = useContext(GlobalContext);

  return (
    <div className="provider-page">
      <Header headerInfo={headerInfo} />
      <div className="divider"></div>
      <Alert />
      <CardBox classNameVal="current-subscription">
        <h4 className="t-card-title text-center">Your current subscription</h4>

        <Flex className="provider-list-holder" justifyContent="center">
          <div className="provider-item">
            <div className="t-price">
              <div>
                ${14.99 * customerDetails?.number_of_providers?.toFixed(2)}{" "}
                <span>/ month</span>
              </div>
              {customerDetails.recording_status ? (
                <span className="t-price-description">
                  Provider fee ({customerDetails.number_of_providers} providers)
                </span>
              ) : (
                ""
              )}
            </div>
          </div>

          {customerDetails.recording_status && (
            <>
              <div className="provider-item">
                <p className="t-price">
                  <FontAwesomeIcon icon={faPlus} className="t-operator" />
                </p>
              </div>
              <div className="provider-item">
                <div className="t-price">
                  <div>
                    ${5.0 * customerDetails.number_of_providers}.00{" "}
                    <span>/ month</span>
                  </div>
                  {customerDetails.recording_status ? (
                    <span className="t-price-description">
                      Recording and archiving
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="provider-item">
                <div className="t-price">
                  <FontAwesomeIcon icon={faEquals} className="t-operator" />
                </div>
              </div>
              <div className="provider-item">
                <div className="t-price">
                  <div>
                    $
                    {customerDetails.recording_status
                      ? parseFloat(
                          14.99 * customerDetails.number_of_providers +
                            5 * customerDetails.number_of_providers
                        ).toFixed(2)
                      : (14.99 * customerDetails.number_of_providers).toFixed(
                          2
                        )}{" "}
                    <span>/ month</span>
                  </div>
                  {customerDetails.recording_status ? (
                    <span className="t-price-description">
                      Total amount per month
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
        </Flex>
        <p className="t-card-desc text-center">
          Your subscription includes{" "}
          <span>
            {customerDetails.number_of_providers}{" "}
            {customerDetails.number_of_providers > 1 ? "providers" : "provider"}
          </span>
        </p>
      </CardBox>
      <CardBox classNameVal="update-provider">
        <Flex
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <div className="provider-info">
            <h4 className="t-card-title">Update your providers</h4>
            <p className="t-card-desc">
              You can adjust the number of providers within your account
              anytime. Adding or removing providers will not affect your
              existing sessions.
            </p>
          </div>
          <div>
            <p className="t-price text-center">
              $14.99 <span>/ provider</span>
            </p>
            <QuantityCounterProviders />
          </div>
        </Flex>
      </CardBox>
      <RecordingArchiving content={RecordingArchivingData} type={"providers"} />
    </div>
  );
};

export default Providers;

import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faFileInvoiceDollar,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../context/GlobalContextProvider";

const Account = () => {
  const { customerDetails } = useContext(GlobalContext);

  const customerID = customerDetails.billsby_customer_id;
  const subscriptionID = customerDetails.billsby_subscription_id;

  useEffect(() => {
    if (typeof window.scanDomBillsby !== "undefined") {
      window.scanDomBillsby();
    }
  }, [customerDetails]);

  return (
    <React.Fragment>
      <Helmet>
        <script
          src="https://checkoutlib.billsby.com/checkout.min.js"
          data-billsby-company={process.env.REACT_APP_BILLSBY_COMPANY}
        ></script>
      </Helmet>
      <div className="account-holder">
        <h4 className="t-card-title text-left">Your Account</h4>
        <p className="t-icon-link">
          <a
            href="#!"
            data-billsby-type="account"
            data-billsby-customer={customerID}
            data-billsby-subscription={subscriptionID}
            data-billsby-action="paymentdetails"
            rel="noreferrer"
          >
            {" "}
            <span className="t-icon">
              <FontAwesomeIcon icon={faCreditCard} />
            </span>
            <span className="t-icon-label">Update payment details</span>
          </a>
        </p>
        <p className="t-icon-link">
          <a
            href="#!"
            data-billsby-type="account"
            data-billsby-customer={customerID}
            data-billsby-subscription={subscriptionID}
            data-billsby-action="billinghistory"
            rel="noreferrer"
          >
            <span className="t-icon">
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
            </span>
            <span className="t-icon-label">View billing history</span>
          </a>
        </p>
        <p className="t-icon-link">
          <Link to="/cancel-subscription">
            <span className="t-icon">
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
            <span className="t-icon-label">Cancel subscription</span>
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
};

export default Account;

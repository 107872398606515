import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const Header = ({ headerInfo }) => (
  <header>
    <h2 className="heading"><Link to={headerInfo.back}><FontAwesomeIcon icon={ faChevronLeft } /> {headerInfo.heading} </Link></h2>
  </header>
)

export default Header
import React, { useContext, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";

import Auth from "../helpers/Auth";
import apiPaths from "../lib/APIPaths";

import { GlobalContext } from "../context/GlobalContextProvider";

const Verification = () => {
  const { setAlertStatus, location } = useContext(GlobalContext);
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (match.params.access_key !== "" || match.params.access_key !== null) {
      if (
        match.params.access_key.includes(
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9."
        )
      ) {
        let params = "";
        const minproviders = new URLSearchParams(location.search).get(
          "minproviders"
        );
        if (minproviders !== null) {
          sessionStorage.setItem("minproviders", minproviders);
          params = "?minproviders=" + minproviders;
        }

        console.log(minproviders);
        axios
          .post(
            apiPaths.portal,
            {
              token: match.params.access_key,
            },
            {
              headers: {
                api_key: process.env.REACT_APP_BE_APIKEY,
              },
            }
          )
          .then(({ data }) => {
            console.log(data);

            Auth.login(
              data.sessionCareCustomerID,
              data.api_key,
              data.subscriptionStatus,
              data.blockFreeTrial,
              () => {
                if (data.subscriptionStatus === "active") {
                  window.location.replace("/session" + params);
                } else if (data.subscriptionStatus === "canceled") {
                  console.log(data);
                  setAlertStatus([
                    {
                      id: 0,
                      type: "error",
                      title: "Your account has been cancelled",
                    },
                  ]);
                  history.push("/error");
                } else {
                  window.location.replace("/" + params);
                  // history.push("/" + params);
                }
              }
            );
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.name === "TokenExpiredError") {
              setAlertStatus([
                { id: 0, type: "error", title: "Access Key not valid" },
              ]);
              history.push("/error");
            }
          });
      } else {
        console.log("invalid page");
        history.push("/404");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default Verification;

import React from 'react';

const BoxPricing = ({ content }) => {
  return (
    content.map((item, i) => {
      return <div key={i} className='box-holder'>{item.data}</div>
    })
  )
}

export default BoxPricing;
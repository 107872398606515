import React, { useContext, useEffect } from "react";
import { Flex } from "rebass";

import { GlobalContext } from "../context/GlobalContextProvider";

const Toggle = () => {
  const { toggle, setToggle, handleToggle, customerDetails } = useContext(
    GlobalContext
  );

  useEffect(() => {
    setToggle(customerDetails.sessions_auto_topup_status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails]);

  return (
    <div className="toggle-wrapper">
      <Flex alignItems="center">
        <label className="switch">
          <input
            type="checkbox"
            checked={toggle && "checked"}
            onChange={handleToggle}
          />
          <span className="slider"></span>
        </label>
        <p>Automatically refill my sessions</p>
      </Flex>
    </div>
  );
};

export default Toggle;

import React from "react";

import SignIn from "./SampleSignIn";
import HomeComponent from "../components/HomeComponent";

const Home = () => {
  // return <HomeComponent />;
  return (
    <>
      <HomeComponent />
      <SignIn />
    </>
  );
};

export default Home;

import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Flex } from "rebass";
import { GlobalContext } from "../context/GlobalContextProvider";
import Moment from "./Moment";

import CardBoxBorder from "./CardBoxBorder";
import CardBoxBorderText from "./CardBoxBorderText";

const GroupSessions = ({ noticeDescriptionData }) => {
  const { customerDetails } = useContext(GlobalContext);
  const [groupCallUsage, setGroupCallUsage] = useState(0);
  const [groupCallBill, setGroupCallBill] = useState(0.0);
  const [nextBilling, setNextBilling] = useState(0);
  const [groupUsageSince, setGroupUsageSince] = useState(0);

  useEffect(() => {
    if (customerDetails !== "") {
      setGroupCallUsage(customerDetails.group_calls.usage);
      setGroupCallBill(
        parseFloat(customerDetails.group_calls.usage * 0.79).toFixed(2)
      );

      if (customerDetails.subscription_details !== null) {
        setNextBilling(customerDetails.subscription_details.nextBillingDate);
      } else {
        setNextBilling(customerDetails.next_billing_date);
      }

      let monthDifference = moment(customerDetails.next_billing_date).diff(
        customerDetails.created_on,
        "month"
      );

      /*
      //Test variable
      let monthDifference = moment("2021-01-17T23:25:47.536042").diff(customerDetails.created_on,"month");
      */

      if (monthDifference === 1) setGroupUsageSince(customerDetails.created_on);
      else
        setGroupUsageSince(
          moment(customerDetails.next_billing_date).subtract(1, "months")
        );
    }
  }, [customerDetails]);

  return (
    <CardBoxBorder classNameVal="group-session">
      <CardBoxBorderText content={noticeDescriptionData}>
        <div className="pricing-wrapper">
          <Flex className="record-info">
            <div className="record-billing text-center">
              <p className="t-val">{groupCallUsage}</p>
              <p className="t-desc">add'l participant since</p>
              <p className="t-date">
                <Moment value={groupUsageSince} />
              </p>
            </div>
            <div className="record-billing text-center">
              <p className="t-val">${groupCallBill}</p>
              <p className="t-desc">will be billed on</p>
              <p className="t-date">
                <Moment value={nextBilling} />
              </p>
            </div>
          </Flex>
        </div>
      </CardBoxBorderText>
    </CardBoxBorder>
  );
};

export default GroupSessions;

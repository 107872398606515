import React from "react";
import { Flex } from 'rebass'

import { GlobalContext } from "../context/GlobalContextProvider";

import Alert from "../components/Alert";

const SampleSignIn = () => {
  const {
    id,
    accessCode,
    handleOnChange,
    handleSendAccessCode,
    handleLogin,
  } = React.useContext(GlobalContext);

  const headingText = {
    marginBottom: "30px",
  };

  const formField = {
    padding: "10px",
    marginLeft: "10px",

    width: "200px",
    height: "40px",

    fontSize: "15px",
    color: " #333333",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    border: "0",
    boxShadow: "0px 0px 6px #00000029",
    borderRadius: "6px",
  };

  const btnLink = {
    margin: "30px auto 0",
  };

  return (
    <React.Fragment>
      <Alert />
      {/* {alertStatus.responseStatus && <Alert />} */}
      <h2 className="heading text-center" style={headingText}>
        Login
      </h2>
      <Flex justifyContent="center" alignItems="center">
        <label>Session Care Customer Id</label>
        <input
          style={formField}
          type="text"
          value={id}
          onChange={handleOnChange}
        />
      </Flex>
      {accessCode === "" ? (
        <button
          onClick={handleSendAccessCode}
          className="btn-blue"
          style={btnLink}
        >
          Send access code
        </button>
      ) : (
        <div>
          <button onClick={handleLogin} className="btn-blue" style={btnLink}>
            Login
          </button>
          {/* <Link
            to={`/plan-selections/${accessCode}`}
            className="btn-blue"
            style={btnLink}
            onClick={handleOnClick}
          >
            Login
          </Link> */}
        </div>
      )}
    </React.Fragment>
  );
};

export default SampleSignIn;

import React from "react";
import Auth from "../helpers/Auth";

const Loading = ({ isLoading }) => {
  if (!Auth.isAuthenticated()) window.location.replace("/");
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loading-wrapper">
          <h2 className="heading text-center">Activating your subscription</h2>
          <p className="sub-heading text-center">
            Please wait a moment whilst we activate your subscription.
          </p>
          <div className="loading-bar">
            <div className="progress-bar"></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Loading;

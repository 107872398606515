//Dependencies
import React from "react";
import { Route, Redirect } from "react-router-dom";

// Shared
import auth from "./Auth";
import { GlobalContext } from "../context/GlobalContextProvider";

const AuthRoute = ({ component: Component, ...rest }) => {
  const { setAlertStatus } = React.useContext(GlobalContext);

  return (
    <React.Fragment>
      <Route
        {...rest}
        render={(props) => {
          if (!auth.isAuthenticated()) {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            if (auth.get("subscriptionStatus") === "active") {
              return (
                <Redirect
                  to={{
                    pathname: "/session",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            } else if (auth.get("subscriptionStatus") === "canceled") {
              setAlertStatus([
                {
                  id: 0,
                  type: "error",
                  title: "Your account has been cancelled",
                },
              ]);
              return (
                <Redirect
                  to={{
                    pathname: "/error",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            } else {
              return <Component {...props} />;
            }
          }
        }}
      />
    </React.Fragment>
  );
};

export default AuthRoute;

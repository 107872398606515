import React, { useContext, useEffect, useState } from "react";
import { Flex } from "rebass";

import CardBox from "../components/CardBox";
import Circle from "../components/Circle";
import { GlobalContext } from "../context/GlobalContextProvider";
import Alert from "../components/Alert";
import Axios from "axios";
import APIPaths from "../lib/APIPaths";
import Auth from "../helpers/Auth";
import Loading from "../components/Loading";

const sessionMessage = {
  message:
    "The payment has been requested for your sessions and they’ll be available in a few minutes.",
  sessionVal: "10,000",
};

const sessionRefill = {
  title: "Auto Session Refill",
  desc:
    "When you turn on Auto Session Refill, we will make sure you never run out of sessions by refilling the balance automatically.",
};

const SuccessfulMessage = () => {
  const {
    payment,
    setPayment,
    history,
    createAlert,
    setSessionBalance,
  } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const billsbyCustomerId = new URLSearchParams(history.location.search).get(
      "cid"
    );
    const billsbySubscriberId = new URLSearchParams(
      history.location.search
    ).get("sid");

    //Get Session Data
    let providers = JSON.parse(Auth.get("providersDetails"));
    const oneTimeChargeDetails = JSON.parse(Auth.get("oneTimeChargeDetails"));

    if (providers !== null && oneTimeChargeDetails !== null) {
      setSessionBalance(oneTimeChargeDetails.sessionsPurchased);
      // console.log(oneTimeChargeDetails)

      // Send HTTP request to save customer id and subscription id
      Axios.put(
        APIPaths.put_subscription_details +
          `/${Auth.get("sessionCareCustomerId")}`,
        {
          billsbyCustomerId,
          billsbySubscriberId,
          subscriptionStatus: "active",
          numOfProviders: providers.numOfProviders,
          recordingStatus: providers.recordingStatus,
        },
        Auth.getAuthConfig()
      )
        .then(({ data }) => {
          console.log(data);
          // Send HTTP request for 1 time charge
          Axios.post(
            APIPaths.post_one_time_charge +
              `/${Auth.get("sessionCareCustomerId")}`,
            JSON.parse(Auth.get("oneTimeChargeDetails")),
            Auth.getAuthConfig()
          )
            .then(({ data }) => {
              console.log(data);
              setPayment(true);
              setIsLoading(false);
              sessionStorage.setItem("subscriptionStatus", "active");
              sessionStorage.removeItem("oneTimeChargeDetails");
              sessionStorage.removeItem("providersDetails");
            })
            .catch((err) => {
              setPayment(false);
              createAlert(
                "error",
                "We were unable to process the payment for the requested number of sessions.",
                `Please proceed to account management to resolve the payment issue.`
              );
              setIsLoading(false);
            });
        })
        .catch((err) => console.log(err));
    } else {
      setPayment(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <main className="success-message">
          <h2 className="heading text-center">Thank you for subscribing!</h2>
          <p className="sub-heading text-center">
            Congratulations, your subscription was processed successfully and
            your account is now ready.
          </p>
          {payment ? (
            <CardBox classNameVal="payment-message">
              <p className="text-center">{sessionMessage.message}</p>
              {/* <Circle circleContent={sessionMessage} /> */}
              <Circle />
            </CardBox>
          ) : (
            <Alert />
          )}
          <CardBox classNameVal="session-refill">
            <Flex justifyContent="space-between" flexWrap="wrap">
              <div className="content-holder">
                <h4 className="t-card-title">{sessionRefill.title}</h4>
                <p className="t-card-desc">{sessionRefill.desc}</p>
              </div>
              <div className="action-holder">
                <a href="/session-refill" className="btn-blue">
                  Manage auto session refill
                </a>
              </div>
            </Flex>
          </CardBox>
          <a href="/session" className="btn-transparent btn-full">
            Go to account management
          </a>
        </main>
      )}
    </>
  );
};

export default SuccessfulMessage;

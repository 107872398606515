import React, { useContext, useEffect } from "react";
import { Flex } from "rebass";

import CardBox from "../components/CardBox";
import Providers from "../components/Providers";
import Sessions from "../components/Sessions";
import Account from "../components/Account";
import AccountRecording from "../components/AccountRecording";
import Alert from "../components/Alert";

import { GlobalContext } from "../context/GlobalContextProvider";
import GroupSessions from "../components/GroupSessions";

const accountRecording = {
  title: "Recording & Archiving",
  desc:
    "This feature allows any provider to record and archive any session. You’re charged $5.00 per month for each provider on your account, then $0.75 for each session that’s recorded.",
};

const noticeDescriptionData = {
  title: "Group Sessions",
  description:
    "If a session includes more than 3 participants, your account is billed a flat fee of $0.79 for each additional participant at the end of the billing month. Group Sessions balance can be tracked in your account management portal.",
};

const Session = () => {
  const { loadCustomerData, history } = useContext(GlobalContext);

  useEffect(() => {
    if (history.action === "PUSH") loadCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="session">
      <h2 className="heading text-left">Your subscription</h2>
      <Alert />
      <CardBox classNameVal="subscription-info">
        <Flex
          className="flex-wrapper"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Providers />
          <Sessions />
          <Account />
        </Flex>
      </CardBox>
      <GroupSessions noticeDescriptionData={noticeDescriptionData} />
      <CardBox classNameVal="recording-archive">
        <AccountRecording accountRecording={accountRecording} />
      </CardBox>
    </main>
  );
};

export default Session;

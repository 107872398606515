import React from 'react'
import { Flex } from 'rebass'

const CardBoxBorderText = ({children, content}) => {
  return(
    <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between">
      <div className="box-border-info">
        <h3 className='t-border-title'>{content.title}</h3>
        <p className='t-border-description'>{content.description}</p>
      </div>
      {children}
    </Flex>
  )
}

export default CardBoxBorderText
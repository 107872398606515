import React from "react";
import BoxPricing from "../components/BoxPricing";
import NoticeList from "../components/NoticeList";
import RecordingArchiving from "../components/RecordingArchiving";
import {
  faCalendarAlt,
  faCouch,
  faFileArchive,
  faTv,
  faUserFriends,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";

import CardBoxBorder from "../components/CardBoxBorder";
import CardBoxBorderText from "../components/CardBoxBorderText";
import PlanSelectionProviders from "../components/PlanSelectionProviders";
import PlanSelectionSessions from "../components/PlanSelectionSessions";
import FreeTrial from "../components/FreeTrial";
import Alert from "../components/Alert";
// import { GlobalContext } from "../context/GlobalContextProvider";

const boxPricingData = [
  {
    data: <PlanSelectionProviders />,
  },
  {
    data: <PlanSelectionSessions />,
  },
];

const noticeListData = {
  title: "Provider Tools Include",
  list: [
    {
      text: "Screen Sharing",
      icon: faTv,
    },
    {
      text: "Queueing",
      icon: faCalendarAlt,
    },
    {
      text: "Secure Chat",
      icon: faUserFriends,
    },
    {
      text: "Scheduling",
      icon: faCouch,
    },
    {
      text: "Waiting Room",
      icon: faUserLock,
    },
    {
      text: "Secure File Transfer",
      icon: faFileArchive,
    },
  ],
  status: "Included",
};

const noticeDescriptionData = {
  title: "Group Sessions",
  description:
    "If a session includes more than 3 participants, your account is billed a flat fee of $0.79 for each additional participant at the end of the billing month. Group Sessions balance can be tracked in your account management portal.",
  price: "0.79",
  price_label: "participant",
};

const recordingArchivingData = {
  title: "Recording & Archiving",
  description:
    "This feature allows any provider to record and archive any session. Decide to record on an ad-hoc basis and be charged $1.50 per recorded session at the end of the billing month OR add the recording plan and pay $5.00 for each active provider per month and a flat fee of $0.75 per recorded session at the end of the billing month.",
  options: [
    {
      price: "1.50",
      price_label: "recording",
      bundle: {},
    },
    {
      price: "5",
      price_label: "provider",
      bundle: {
        price: "0.75",
        price_label: "recording",
      },
    },
  ],
};

const PlanSelection = () => {
  // const { recordingStatus } = useContext(GlobalContext)

  return (
    <div className="plan-selection-container">
      <FreeTrial />
      <Alert />
      <div className="boxes-wrapper">
        <BoxPricing content={boxPricingData} />
      </div>
      <CardBoxBorder classNameVal="notice-holder">
        <NoticeList content={noticeListData} />
      </CardBoxBorder>
      <CardBoxBorder classNameVal="group-session">
        <CardBoxBorderText content={noticeDescriptionData}>
          <div className="pricing-wrapper">
            <p className="t-price">
              ${noticeDescriptionData.price}{" "}
              <span>/ {noticeDescriptionData.price_label}</span>
            </p>
          </div>
        </CardBoxBorderText>
      </CardBoxBorder>
      <RecordingArchiving
        content={recordingArchivingData}
        type={"plan-selection"}
      />
    </div>
  );
};

export default PlanSelection;

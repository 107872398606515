const base_url = process.env.REACT_APP_BE_URL;
// const base_url = "http://localhost:9000";

const base_customers_api = base_url + "/api/customers";

const APIPaths = {
  base_endpoint: base_url,
  portal: base_url + "/api/portal",
  base_customers_api: base_customers_api,

  customer_details: base_customers_api + "/account/details",
  pending_purchases: base_customers_api + "/account/sessions/pending",

  post_one_time_charge: base_customers_api + "/account/charge",

  delete_subscription: base_customers_api + "/account/subscription/cancel",

  put_refill_preference: base_customers_api + "/account/manage/refill",

  put_subscription_details: base_customers_api + "/subscription/update",

  put_plan_change: base_customers_api + "/account/plan/change",

  get_free_trial_status: base_customers_api + "/free_trial",
  create_free_trial: base_customers_api + "/free_trial/create",
  update_free_trial: base_customers_api + "/free_trial/update",
};
export default APIPaths;

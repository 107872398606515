import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";
import axios from "axios";
import apiPaths from "../lib/APIPaths";
import Auth from "../helpers/Auth";

const Circle = () => {
  const { customerDetails, sessionBalance } = useContext(GlobalContext);

  const [pendingPurchase, setPendingPurchase] = useState({
    total: 0,
    pending_invoices: [],
  });

  const loadPendingPurchase = () => {
    axios
      .get(
        apiPaths.pending_purchases + `/${Auth.get("sessionCareCustomerId")}`,
        Auth.getAuthConfig()
      )
      .then(({ data }) => {
        if (data.total !== 0) setPendingPurchase(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const converToString = (number) => {
    if (number >= 1000) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number;
    }
  };

  useEffect(() => {
    loadPendingPurchase();
  }, []);

  return (
    <div className="circle-wrapper">
      <h3>
        {sessionBalance !== undefined
          ? converToString(sessionBalance)
          : converToString(customerDetails.sessions_balance)}
      </h3>
      <span>Sessions remaining</span>
      {pendingPurchase.total !== 0 && (
        <div className="circle-pending-wrapper">
          <h3>{converToString(pendingPurchase.total)}</h3>
          <span>Payment processing</span>
        </div>
      )}
    </div>
  );
};

export default Circle;

//Dependencies
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

// Shared
import auth from "./Auth";

import { GlobalContext } from "../context/GlobalContextProvider";

const AuthRoute = ({ component: Component, ...rest }) => {
  const { createAlert } = useContext(GlobalContext);
  return (
    <React.Fragment>
      <Route
        {...rest}
        render={(props) => {
          if (!auth.isAuthenticated()) {
            return <Component {...props} />;
          } else {
            if (auth.get("subscriptionStatus") === "active") {
              return (
                <Redirect
                  to={{
                    pathname: "/session",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            } else if (auth.get("subscriptionStatus") === "new") {
              return (
                <Redirect
                  to={{
                    pathname: "/plan-selections",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            } else if (auth.get("subscriptionStatus") === "canceled") {
              createAlert("error", "Your account has been cancelled", "");
              return (
                <Redirect
                  to={{
                    pathname: "/error",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            } else {
              window.location.replace("/plan-selections/" + auth.get("sessionCareCustomerId"))
              return (
                <></>
                // <Redirect
                //   to={{
                //     pathname:
                //       "/plan-selections/" + auth.get("sessionCareCustomerId"),
                //     state: {
                //       from: props.location,
                //     },
                //   }}
                // />
              );
            }
          }
        }}
      />
    </React.Fragment>
  );
};

export default AuthRoute;

import React, { useContext } from "react";
import { Flex } from "rebass";

import Header from "../components/Header";
import CardBox from "../components/CardBox";
import Circle from "../components/Circle";
import QuantityCounterSessions from "../components/QuantityCounterSessions";
import { GlobalContext } from "../context/GlobalContextProvider";

const headerInfo = {
  back: "/session",
  heading: "Add more sessions",
};

const AddSession = () => {
  const {
    sessionPrice,
    newSessionPrice,
    handlePurchaseSession,
    hasValue,
  } = useContext(GlobalContext);

  return (
    <div className="add-session-wrapper">
      <Header headerInfo={headerInfo} />
      <CardBox classNameVal="add-session-info">
        <Circle />
        <p className="t-card-desc text-center">
          Sessions are unique encounters conducted via secure videos, voice, and
          chat. The session balance is shared among all providers within your
          account. Each session includes up to 20 minutes of live video and
          voice interaction for up to three participants. Unused sessions
          rollover and do not expire.
        </p>
      </CardBox>
      <CardBox classNameVal="add-session-action">
        <Flex flexWrap="wrap" className="session-list">
          <Flex
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <h4 className="t-card-title">Add one-time sessions</h4>
            <p className="t-price">
              ${sessionPrice} <span>/ session</span>
            </p>
          </Flex>
          <Flex
            className="adding-session"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <ul>
              <li>$0.80 / session when you buy 1,500</li>
              <li>$0.76 / session when you buy 5,000</li>
              <li>$0.71 / session when you buy 10,000+</li>
            </ul>
            <QuantityCounterSessions />
          </Flex>
        </Flex>
      </CardBox>
      <div className="total-holder text-center">
        <div className="t-price">
          <div>
            ${newSessionPrice} <span>/ one-time</span>
          </div>
        </div>
        <div className="action-holder">
          <button
            className={`btn-blue btn-full ${hasValue && "btn-disabled"}`}
            type="button"
            onClick={handlePurchaseSession}
          >
            Pay ${newSessionPrice} and add sessions
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSession;

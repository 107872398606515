import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContextProvider";

const Providers = () => {
  const { customerDetails, providerPrice } = useContext(GlobalContext);

  const [providerCount, setProviderCount] = useState(0);
  const [providerAmount, setProviderAmount] = useState("0.00");

  useEffect(() => {
    let value = 0;

    // Sets provider count
    if (customerDetails.number_of_providers !== undefined) {
      setProviderCount(customerDetails.number_of_providers);
    }
    value =
      Math.round(customerDetails.number_of_providers * providerPrice * 100) /
      100;
    // if (customerDetails.recording_status) {
    //   value =
    //     // Math.round(
    //     //   (customerDetails.number_of_providers * providerPrice
    //     //     +
    //     //     5 * customerDetails.number_of_providers) *
    //     //     100
    //     // ) / 100;

    //   console.log("Has recording: " + value);
    // } else {
    //   value = customerDetails.number_of_providers * providerPrice;
    //   console.log("No recording: " + value);
    // }

    value = parseFloat(value.toFixed(2));
    setProviderAmount(value);
    // eslint-disable-next-line
  }, [customerDetails]);

  return (
    <div className="providers-holder">
      <h4 className="t-card-title text-left">Your Providers</h4>
      <React.Fragment>
        <div className="provider-item">
          <p className="t-price">
            ${providerAmount}
            {/* {customerDetails.recording_status ? (
              <React.Fragment>
                {Math.round(
                  (providerCount * providerPrice + 5 * providerCount) * 100
                ) / 100}
              </React.Fragment>
            ) : (
              <React.Fragment>{providerCount * providerPrice}</React.Fragment>
            )} */}
            <span> / month</span>
          </p>
          <p className="t-note">Provider fee ({providerCount} providers)</p>
        </div>
        {customerDetails.recording_status && (
          <>
            <div className="provider-item">
              <p className="t-price">
                ${(5.0 * customerDetails.number_of_providers).toFixed(2)}{" "}
                <span>/ month</span>
              </p>
              <p className="t-note">Recording and archiving</p>
            </div>
            <hr className="horizontal-split" />
            <div className="provider-item">
              <p className="t-price">
                $
                <React.Fragment>
                  {Math.round((providerAmount + 5 * providerCount) * 100) / 100}
                </React.Fragment>{" "}
                <span>/ month</span>
              </p>
              <p className="t-note">Total amount per month</p>
            </div>
          </>
        )}
      </React.Fragment>
      <Link to="/providers" className="btn-blue">
        Update providers
      </Link>
    </div>
  );
};

export default Providers;

import React from 'react'

import LoadingComponent from '../components/Loading'

const Loading = () => {
  return (
    <LoadingComponent />
  )
}

export default Loading
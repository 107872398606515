import React, { useContext } from "react";

import DecodeContent from "./DecodeContent";
import { GlobalContext } from "../context/GlobalContextProvider";

const Alert = () => {
  const { alertStatus } = useContext(GlobalContext);

  return (
    <React.Fragment>
      {alertStatus.map((item) => {
        return (
          <div
            key={item.id}
            className={`alert 
              ${
                item.type === "success"
                  ? "alert-success"
                  : item.type === "error" && "alert-error"
              }`}
          >
            <p className="t-alert">{item.title}</p>
            {item.description && (
              <p className="t-alert t-action">
                <DecodeContent objVal={item.description} />
              </p>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default Alert;

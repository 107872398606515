import React from "react";

import MomentFormat from "react-moment";

const Moment = ({ value }) => (
  <>
    {value !== null ? (
      <MomentFormat format="MM/DD/YYYY">{value}</MomentFormat>
    ) : (
      "'No recorded usage'"
    )}
  </>
);

export default Moment;

import React, { useContext } from 'react'
import { GlobalContext } from '../context/GlobalContextProvider'

import QuantityCounterProviders from './QuantityCounterProviders'

const PlanSelectionProviders = () => {
  const {
    providerPrice
  } = useContext(GlobalContext)

  return(
    <React.Fragment>
      <h3 className='title'>Providers</h3>
      <p className='desc'>Choose the number of providers who will conduct Telehealth sessions. You will be charged monthly for each provider within your account. Provider licenses do not include sessions, they will be added separately.</p>
      <div className='pricing-wrapper'>
        <div className='pricing-holder'>
          <span className='price'>${providerPrice} </span> <span>/ provider</span>
        </div>
      </div>
      <QuantityCounterProviders />
    </React.Fragment>
  )
}

export default PlanSelectionProviders
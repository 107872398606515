import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import apiPaths from "../lib/APIPaths";
import Auth from "../helpers/Auth";
import moment from "moment";

export const GlobalContext = React.createContext();

export const GlobalContextProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  const [alertStatus, setAlertStatus] = useState([]);
  const [hasValue, setHasValue] = useState(false);

  const [id, setId] = useState("");

  const [accessCode, setAccessCode] = useState("");

  const [toggle, setToggle] = useState(false);
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const providerPrice = 14.99;
  const sessionPrice = 0.89;
  const [customerDetails, setCustomerDetails] = useState("");
  const [recordingPlan, setRecordingPlan] = useState(0);
  const [recordingStatus, setRecordingStatus] = useState();
  const [changed, setChanged] = useState(false);
  const [payment, setPayment] = useState();
  const [numberOfProviders, setNumberOfProviders] = useState(1);
  const [sessionCount, setSessionCount] = useState(25);
  const [newSessionRate, setNewSessionRate] = useState(sessionPrice);
  const [newSessionPrice, setNewSessionPrice] = useState(0);
  const [newProviderPrice, setNewProviderPrice] = useState();
  const [sessionBalance, setSessionBalance] = useState();
  const [loadingData, setLoadingData] = useState(false);

  // console.log(Auth.get("sessionCareCustomerId"));

  const loadCustomerData = () => {
    if (!loadingData) {
      setLoadingData(true);
      axios
        .get(
          apiPaths.customer_details + `/${Auth.get("sessionCareCustomerId")}`,
          Auth.getAuthConfig()
        )
        .then(({ data }) => {
          setCustomerDetails(data);

          // Set number of providers
          if (data.number_of_providers === 0) {
            setNumberOfProviders(1);
          } else setNumberOfProviders(data.number_of_providers);

          //if there is unpaid invoices
          data.invoices.forEach((item) => {
            if (item.status === "Unpaid") {
              createAlert(
                "error",
                "There is a problem with one of your invoices.",
                `Please <a href="https://app.billsby.com/invoice/${item.invoiceId}" target="_blank">view the invoice </a> to resolve the payment issue.`
              );
            }
          });
          if (Auth.get("subscriptionStatus") !== data.subscription_status)
            sessionStorage.setItem(
              "subscriptionStatus",
              data.subscription_status
            );

          setLoadingData(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingData(false);
        });
    } else return;
  };

  /* ------------------------------ Customer Details ----------------------------- */
  useEffect(() => {
    if (history.location.pathname !== "/") loadCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* ------------------------------ Selecting and subscribing to a plan ----------------------------- */

    if (customerDetails !== "") {
      setRecordingStatus(customerDetails.recording_status);

      if (customerDetails.recording_status) {
        setRecordingPlan(1);
      }

      if (customerDetails.number_of_providers <= 0) {
        setCustomerDetails({ ...customerDetails, number_of_providers: 1 });
      }

      if (recordingPlan === 1) {
        setNewProviderPrice(
          Math.round(
            (customerDetails.number_of_providers * providerPrice +
              5 * customerDetails.number_of_providers) *
              100
          ) / 100
        );
      } else
        setNewProviderPrice(
          customerDetails.number_of_providers * providerPrice
        );
    }

    /* ------------------------- Check recording status ------------------------- */
    if (customerDetails.recording_status) {
      setNewProviderPrice(
        Math.round(
          (numberOfProviders * providerPrice + 5 * numberOfProviders) * 100
        ) / 100
      );
    } else setNewProviderPrice(numberOfProviders * providerPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails]);

  /* ---------------------- Calculate New Provider Price ---------------------- */

  useEffect(() => {
    if (customerDetails.recording_status) {
      setNewProviderPrice(
        Math.round(
          (numberOfProviders * providerPrice + 5 * numberOfProviders) * 100
        ) / 100
      );
    } else setNewProviderPrice(numberOfProviders * providerPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfProviders]);

  /* -------------------- Calculate One Time Session Price -------------------- */

  useEffect(() => {
    var newPrice = 0;
    if (sessionCount <= 1499) {
      setNewSessionRate(0.89);
      newPrice = (sessionCount * 0.89).toFixed(2);
    } else if (sessionCount >= 1500 && sessionCount <= 4999) {
      setNewSessionRate(0.8);
      newPrice = (sessionCount * 0.8).toFixed(2);
    } else if (sessionCount >= 5000 && sessionCount <= 9999) {
      setNewSessionRate(0.76);
      newPrice = (sessionCount * 0.76).toFixed(2);
    } else {
      setNewSessionRate(0.71);
      newPrice = (sessionCount * 0.71).toFixed(2);
    }
    newPrice = parseFloat(newPrice);
    setNewSessionPrice(newPrice);
  }, [sessionCount]);

  /* ------------------------------ Add Session ----------------------------- */

  const handlePurchaseSession = () => {
    let date = moment().format("MMMM-DD-YYYY");

    const payload = {
      currencyCode: "USD",
      amount: newSessionPrice * 100,
      description: `${sessionCount} Sessions at $${newSessionRate} per session (${date})`,
      sessionsPurchased: sessionCount,
    };

    console.log(payload);
    axios
      .post(
        apiPaths.post_one_time_charge + `/${Auth.get("sessionCareCustomerId")}`,
        payload,
        Auth.getAuthConfig()
      )
      .then((result) => {
        createAlert(
          "success",
          "We’ve requested payment for your sessions. As soon as the payment is confirmed, you’ll see them in your account.",
          "Do you need to do anything else today?"
        );
        history.push("/session");
      })
      .catch((err) => {
        createAlert(
          "error",
          "Unfortunately, we were unable to process your request at this time.",
          ` Please call us at <a href="tel:7205157384" class="link-nonunderline">(720) 515-7384</a> or email us at <a href="mailTo:billing@sessioncare.com" class="link-nonunderline">billing@sessioncare.com</a> and we will help to quickly resolve the issue. `
        );
        history.push("/session");
      });
  };

  /* ---------------------- Update Auto Refill Preference --------------------- */
  const handleUpdateAutoRefill = (
    sessionPerAutoTopup,
    sessionAutoTopupTrigger,
    sessionAutoTopupStatus
  ) => {
    console.log("running");

    axios
      .put(
        apiPaths.put_refill_preference +
          `/${Auth.get("sessionCareCustomerId")}`,
        {
          sessionsAutoTopupStatus: sessionAutoTopupStatus,
          sessionsPerAutoTopup: sessionPerAutoTopup,
          autoTopUpTrigger: sessionAutoTopupTrigger,
        },
        Auth.getAuthConfig()
      )
      .then((result) => {
        // Save session settings to customer details
        setCustomerDetails({
          ...customerDetails,
          auto_topup_status: sessionAutoTopupStatus,
          sessions_per_auto_topup: sessionPerAutoTopup,
          auto_topup_trigger: sessionAutoTopupTrigger,
        });
        // Reset sessionCount state to 25
        setSessionCount(25);
        createAlert(
          "success",
          "Your account has been updated successfully",
          "Do you need to do anything else today?"
        );
        history.push("/session");
      })
      .catch((err) => {
        createAlert(
          "error",
          "Unfortunately, we were unable to process your request at this time.",
          ` Please call us at <a href="tel:7205157384" class="link-nonunderline">(720) 515-7384</a> or email us at <a href="mailTo:billing@sessioncare.com" class="link-nonunderline">billing@sessioncare.com</a> and we will help to quickly resolve the issue. `
        );
        history.push("/session");
      });
  };
  /* ------------------------------ For the meantime ----------------------------- */

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleRecordingPlan = (index) => {
    // Set boolean to numerical value to match index
    const _currentRecording = recordingStatus ? 1 : 0;

    // If index and current plan is matched, disable button
    if (index === _currentRecording) {
      setRecordingPlan(index);
      setChanged(false);
    }
    // Else, enable button
    else {
      setRecordingPlan(index);
      if (!changed) {
        setChanged(!changed);
      }
    }
  };

  /* ------------------------------ Cancel Subscription ----------------------------- */

  const handleContinueCancel = () => {
    setCancelSubscription(true);
  };

  const handleCancelSubscription = () => {
    axios
      .delete(
        apiPaths.delete_subscription + `/${Auth.get("sessionCareCustomerId")}`,
        Auth.getAuthConfig()
      )
      .then((result) => {
        sessionStorage.setItem("subscriptionStatus", "cancelled");
        createAlert("error", "Your account has now been cancelled.", "");
        history.push("/cancellation");
      })
      .catch((err) => {
        createAlert(
          "error",
          "Failed cancelling your account. Please try again.",
          ""
        );
        history.push("/cancellation");
      });
  };

  /* ------------------------------ Login + Send Access Code ----------------------------- */

  const handleOnChange = (e) => {
    const value = e.target.value;
    setId(value);
  };

  const handleOnClick = () => {
    axios
      .get(apiPaths.portal + `/${id}`, {
        headers: {
          api_key: process.env.REACT_APP_BE_APIKEY,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setAccessCode(data.token);
      })
      .catch((err) => {
        createAlert("error", "Access key not valid.", "");
      });
  };

  const handleSendAccessCode = () => {
    console.log(process.env.REACT_APP_BE_APIKEY);
    axios
      .get(apiPaths.portal + `/${id}`, {
        headers: {
          api_key: process.env.REACT_APP_BE_APIKEY,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setAccessCode(data.token);
      })
      .catch((err) => {
        createAlert("error", "Access key not valid.", "");
      });
  };

  const handleLogin = () => {
    history.push("/" + accessCode);
  };

  /* ------------------------------ Toast ----------------------------- */

  const createAlert = (type, title, desc) => {
    const id = Math.floor(Math.random() * 100 + 1);
    let toastProperties = {};

    if (type === "success") {
      toastProperties = {
        id,
        type: type,
        title: title,
        description: desc,
      };
    } else {
      toastProperties = {
        id,
        type: type,
        title: title,
        description: desc,
      };
    }

    setAlertStatus([...alertStatus, toastProperties]);
  };

  // const deleteAlert = (id) => {
  //   var tempList = alertStatus;
  //   const index = tempList.findIndex((e) => e.id === id);
  //   tempList.splice(index, 1);
  //   setAlertStatus([...tempList]);
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (alertStatus.length) deleteAlert(alertStatus[0].id);
  //   }, 30000); // 15sec
  //   return () => {
  //     clearInterval(interval);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [alertStatus]);

  const saveOneTimeChargeDetailsToCache = () => {
    var recordingStatusVal;
    if (recordingPlan === 1) {
      recordingStatusVal = true;
    } else {
      recordingStatusVal = false;
    }

    let date = moment().format("MMMM-DD-YYYY");
    sessionStorage.setItem(
      "oneTimeChargeDetails",
      JSON.stringify({
        currencyCode: "USD",
        amount: newSessionPrice * 100,
        description: `${sessionCount} Sessions at $${newSessionRate} per session (${date})`,
        sessionsPurchased: sessionCount,
      })
    );
    sessionStorage.setItem(
      "providersDetails",
      JSON.stringify({
        recordingStatus: recordingStatusVal,
        numOfProviders: numberOfProviders,
      })
    );
  };

  /* ------------------------- Every time path changes ------------------------ */

  React.useEffect(() => {
    // console.log("Path changes", location.pathname);
    if (
      location.pathname === "/session" ||
      location.pathname === "/cancellation" ||
      location.pathname === "/error"
    ) {
      // console.log("show notifications");
    } else {
      // console.log("cleared notifications");
      if (alertStatus.length !== 0) {
        setAlertStatus([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]); // runs useEffect only on location change

  return (
    <GlobalContext.Provider
      value={{
        history,
        location,
        id,
        setId,
        accessCode,
        setAccessCode,
        handleOnChange,
        handleOnClick,
        handleSendAccessCode,
        handleLogin,

        createAlert,

        customerDetails,
        setCustomerDetails,

        providerPrice,
        sessionPrice,

        sessionCount,
        setSessionCount,
        newSessionRate,
        setNewSessionRate,
        newSessionPrice,
        setNewSessionPrice,
        newProviderPrice,
        setNewProviderPrice,
        sessionBalance,
        setSessionBalance,
        recordingStatus,
        setRecordingStatus,
        changed,
        setChanged,
        handlePurchaseSession,

        numberOfProviders,
        setNumberOfProviders,
        payment,
        setPayment,
        alertStatus,
        setAlertStatus,
        toggle,
        setToggle,
        handleToggle,
        recordingPlan,
        handleRecordingPlan,
        cancelSubscription,
        handleContinueCancel,
        handleCancelSubscription,
        handleUpdateAutoRefill,
        saveOneTimeChargeDetailsToCache,

        loadCustomerData,
        hasValue,
        setHasValue,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;

import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Flex } from "rebass";
import Auth from "../helpers/Auth";
import APIPaths from "../lib/APIPaths";
import moment from "moment";

import Circle from "./Circle";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";

const override = css`
  display: block;
`;

const FreeTrial = () => {
  const [freeTrialStatus, setFreeTrialStatus] = useState("new");
  const [freeTrialExp, setFreeTrialExp] = useState("MMM DD YYYY");

  const enableFreeTrial = () => {
    setFreeTrialStatus("loading");
    setTimeout(() => {
      const url =
        APIPaths.create_free_trial + `/${Auth.get("sessionCareCustomerId")}`;
      Axios.post(
        url,
        {},
        {
          headers: {
            api_key: process.env.REACT_APP_BE_APIKEY,
          },
        }
      )
        .then(({ data }) => {
          console.log(data);
          // window.location.replace("/session");
          // Show notification
          sessionStorage.setItem("freetrialExpDate", data.expired_at);
          setFreeTrialExp(moment(data.expired_at).format("MMM DD YYYY"));
          setFreeTrialStatus("freetrial");
        })
        .catch((err) => {
          console.log(err);
          // Show error
        });
      console.log(url);
    }, 3000);
  };

  useEffect(() => {
    if (Auth.get("blockFreeTrial") !== "true") {
      setFreeTrialStatus(Auth.get("subscriptionStatus").toLowerCase());

      if (
        Auth.get("subscriptionStatus").toLowerCase() === "freetrial" ||
        Auth.get("subscriptionStatus").toLowerCase() === "expiredfreetrial"
      ) {
        console.log(
          "Free trial status found. Fetch free trial info will not commence"
        );
        Axios.get(
          APIPaths.get_free_trial_status +
            `/${Auth.get("sessionCareCustomerId")}`,
          {
            headers: {
              api_key: process.env.REACT_APP_BE_APIKEY,
            },
          }
        )
          .then(({ data }) => {
            if (data.expired_at !== undefined) {
              sessionStorage.setItem("freetrialExpDate", data.expired_at);
              setFreeTrialExp(moment(data.expired_at).format("MM/DD/YYYY"));
              if (data.subscription_status !== freeTrialStatus) {
                setFreeTrialStatus(data.subscription_status);
              }
            }
          })
          .catch((err) => {
            // console.log(err);
            console.log("No free trial status found");
          });
      } else {
        console.log(
          "Free trial status not found. Fetch free trial info will not commence"
        );
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {Auth.get("blockFreeTrial") === "true" ? (
        ""
      ) : (
        <div className="card-box-border">
          <Flex
            alignItems="center"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <div
              className={
                freeTrialStatus !== "freetrial"
                  ? "box-border-info-full"
                  : "box-border-info"
              }
            >
              <h3 className="t-border-title">Free Trial</h3>
              {freeTrialStatus === "new" && (
                <>
                  <p className="t-border-description">
                    <b>Want to give us a try?</b>
                    <br />
                    Sign up for a free trial now and start using SessionCare
                    today. We enable up to 5 providers to share 100 sessions
                    during the trial period.
                  </p>
                  <button
                    className="btn-full btn-blue btn-trial"
                    onClick={enableFreeTrial}
                  >
                    Start 7 Days Free Trial!
                  </button>
                </>
              )}
              {freeTrialStatus === "loading" && (
                <>
                  <p className="t-border-description">
                    <b>Want to give us a try?</b>
                    <br />
                    Sign up for a free trial now and start using SessionCare
                    today. We enable up to 5 providers to share 100 sessions
                    during the trial period.
                  </p>
                  <button className="btn-full btn-blue btn-trial">
                    <div className="sweet-loading">
                      <PulseLoader
                        css={override}
                        size={12}
                        color={"#354e73"}
                        loading={true}
                      />
                    </div>
                  </button>
                </>
              )}
              {freeTrialStatus === "freetrial" && (
                <>
                  <p className="t-border-description">
                    Your free trial ends on {freeTrialExp}. Your free trial
                    sessions will be awarded once your account has been created.
                    <br />
                    <br />
                    The trial subscription will not be auto-renewed. Please
                    return to this page to enable your full subscription.
                  </p>
                </>
              )}
              {freeTrialStatus === "expiredfreetrial" && (
                <>
                  <p className="t-border-description">
                    Free trial period ended on {freeTrialExp}.
                    <br />
                    <br />
                    To enable a subscription, please select the desired options
                    from the menu below.
                  </p>
                </>
              )}
            </div>
            {freeTrialStatus === "freetrial" && <Circle />}
          </Flex>
        </div>
      )}
    </>
  );
};

export default FreeTrial;

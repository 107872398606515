import React, { useContext, useEffect, useState } from "react";
import { Flex } from "rebass";
import moment from "moment";
import { Helmet } from "react-helmet";

import { GlobalContext } from "../context/GlobalContextProvider";

import axios from "axios";
import apiPaths from "../lib/APIPaths";
import auth from "../helpers/Auth";

import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import runScanDom from "../lib/billsbyScanDom";
import { faEquals, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const override = css`
  display: block;
`;

const ChoiceTotal = ({ type }) => {
  const {
    history,
    newProviderPrice,
    setNewProviderPrice,
    newSessionPrice,
    changed,
    setChanged,
    createAlert,
    customerDetails,
    setCustomerDetails,
    recordingPlan,
    saveOneTimeChargeDetailsToCache,
    numberOfProviders,
    providerPrice,
    hasValue,
  } = useContext(GlobalContext);

  const [isDomScanned, setIsDomScanned] = useState(false);

  const checkOutref = React.createRef();

  const date = new Date();
  const day = moment(date).format("Do");
  var nextMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()
  );
  const newBillingDate = moment(nextMonth).add(10, "days").calendar();

  const handleUpdateProvider = () => {
    var planId;
    var cycleId;
    if (recordingPlan === 1) {
      planId = parseInt(process.env.REACT_APP_PLANID_ON);
      cycleId = parseInt(process.env.REACT_APP_CYCLEID_ON);
    } else {
      planId = parseInt(process.env.REACT_APP_PLANID_OFF);
      cycleId = parseInt(process.env.REACT_APP_CYCLEID_OFF);
    }

    if (
      planId === customerDetails.subscription_details.planId &&
      cycleId === customerDetails.subscription_details.cycleId &&
      numberOfProviders === customerDetails.number_of_providers
    ) {
      console.log("plan was not changed, nothing happens");
      createAlert(
        "error",
        "No changes in plan was made.",
        `Selected plan is the same as current plan.`
      );
      history.push("/session");
    }

    let payload = {
      planId,
      cycleId,
      units: numberOfProviders,
      planChangeType: 1,
      issueRefund: true,
    };

    axios
      .put(
        apiPaths.put_plan_change + `/${auth.get("sessionCareCustomerId")}`,
        payload,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        console.log(data);
        setNewProviderPrice(newProviderPrice);
        setCustomerDetails({
          ...customerDetails,
          number_of_providers: numberOfProviders,
          subscription_details: {
            ...customerDetails.subscription_details,
            nextBillingDate: newBillingDate,
            planId: planId,
            cycleId: cycleId,
          },
        });
        if (changed) {
          setChanged(!changed);
        }
        createAlert(
          "success",
          "Your account has been updated successfully.",
          "Do you need to do anything else today?"
        );
        history.push("/session");
      })
      .catch((err) => {
        console.log(err);
        createAlert(
          "error",
          "Unfortunately, we were unable to process your request at this time.",
          `Please call us at <a href="tel:720-515-7384">(720) 515-7384</a> or email us at <a href="mailTo:billing@sessioncare.com">billing@sessioncare.com</a> and we will help to quickly resolve the issue.`
        );
        history.push("/session");
      });
  };

  const isAvailableForPlanPurchase = () => {
    if (
      auth.get("subscriptionStatus") === "new" ||
      auth.get("subscriptionStatus") === "New" ||
      auth.get("subscriptionStatus") === "freetrial" ||
      auth.get("subscriptionStatus") === "expiredfreetrial"
    )
      return true;
    else return false;
  };

  const handleSetupPlan = () => {
    if (
      auth.get("subscriptionStatus") === "freetrial" ||
      auth.get("subscriptionStatus") === "expiredfreetrial"
    ) {
      console.log("Redirect to plan selection");
      history.push("/");
    } else return console.log("Button not working with given conditions");
  };

  /**
   * Programmatically click checkout.
   */
  const callScreenFunction = () => {
    // Run Scan Billsby
    runScanDom();

    // Set one time charge details to cache
    saveOneTimeChargeDetailsToCache();

    // Click Check out
    checkOutref.current.click();
  };

  /**
   * Add Allowance
   */

  const addAllowance = () => {
    if (
      process.env.REACT_APP_GROUPSESSIONS_ALLOWANCE_ID !== "" &&
      process.env.REACT_APP_RECORDINGSESSIONS_ALLOWANCE_ID !== ""
    ) {
      return `[${process.env.REACT_APP_GROUPSESSIONS_ALLOWANCE_ID}, ${process.env.REACT_APP_RECORDINGSESSIONS_ALLOWANCE_ID}]`;
    } else return "";
  };

  /**
   * Sets an interval for scanning dom per second
   * And stops scanning after 2 seconds
   */
  useEffect(() => {
    if (customerDetails.first_name !== undefined) {
      var timerVar = setInterval(timer, 1000);

      function timer() {
        runScanDom();
      }

      function stopFunction() {
        clearInterval(timerVar);
      }

      setTimeout(() => {
        stopFunction();
        setIsDomScanned(true);
      }, 2000);
    }
  }, [customerDetails]);

  return (
    <div className="total-holder text-center">
      {isAvailableForPlanPurchase() &&
        customerDetails.first_name !== undefined && (
          <Helmet>
            <script>
              {`window.billsbyData = {
              firstName: "${customerDetails.first_name}",
              lastName: "${customerDetails.last_name}",
              email: "${customerDetails.email_address}",
              billingAddressLine1: "${customerDetails.billing_address_line_1}",
              billingAddressLine2: "${customerDetails.billing_address_line_2}",
              billingAddressCity: "${customerDetails.billing_city}",
              billingAddressState: "${customerDetails.billing_state}",
              billingAddressZip: "${customerDetails.billing_post_code}",
              billingAddressCountry: "${customerDetails.billing_country}",
              customFields: [
                {
                  customFieldId: ${process.env.REACT_APP_CUSTOMFIELD_ID_SESSIONCAREID},
                  value: "${customerDetails.sessioncare_unique_id}",
                }, 
                {
                  customFieldId: ${process.env.REACT_APP_CUSTOMFIELD_ID_COMPANYNAME},
                  value: "${customerDetails.company_name}",
                }
              ]
            };`}
            </script>

            {/* Checkout code */}
            <script
              src="https://checkoutlib.billsby.com/checkout.min.js"
              data-billsby-company={process.env.REACT_APP_BILLSBY_COMPANY}
            ></script>
          </Helmet>
        )}
      {auth.get("subscriptionStatus") === "active" && changed && (
        <h4 className="t-card-title text-center">Your new subscription</h4>
      )}
      <Flex
        className={`provider-list-holder ${changed && "new-plan"}`}
        justifyContent="center"
      >
        {type === "plan-selection" ? (
          <>
            <div className="provider-item">
              <div className="t-price">
                <div>
                  $
                  {recordingPlan === 1 ? (
                    <React.Fragment>
                      {Math.round(
                        (numberOfProviders * providerPrice +
                          5 * numberOfProviders) *
                          100
                      ) / 100}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {numberOfProviders * providerPrice}
                    </React.Fragment>
                  )}
                  <span> / month</span>
                </div>
              </div>
            </div>
            <div className="provider-item">
              <div className="t-price">
                <div>
                  ${Math.round(newSessionPrice * 100) / 100}
                  <span> / one-time</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="provider-item">
              <div className="t-price">
                <div>
                  ${" "}
                  <React.Fragment>
                    {(numberOfProviders * providerPrice).toFixed(2)}
                  </React.Fragment>
                  {/* {recordingPlan === 1 ? (
                  <React.Fragment>
                    {(
                      Math.round(
                        (numberOfProviders * providerPrice +
                          5 * numberOfProviders) *
                          100
                      ) / 100
                    ).toFixed(2)}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {(numberOfProviders * providerPrice).toFixed(2)}
                  </React.Fragment>
                )} */}
                  <span> / month</span>
                </div>
                {recordingPlan ? (
                  <span className="t-price-description">
                    Provider fee ({numberOfProviders} providers)
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            {recordingPlan ? (
              <>
                <div className="provider-item">
                  <div className="t-price">
                    <FontAwesomeIcon icon={faPlus} className="t-operator" />
                  </div>
                </div>
                <div className="provider-item">
                  <div className="t-price">
                    <div>
                      ${numberOfProviders * 5}.00 <span>/ month</span>
                    </div>
                    <span className="t-price-description">
                      Recording and archiving
                    </span>
                  </div>
                </div>
                <div className="provider-item">
                  <div className="t-price">
                    <FontAwesomeIcon icon={faEquals} className="t-operator" />
                  </div>
                </div>
                <div className="provider-item">
                  <div className="t-price">
                    <div>
                      $
                      <React.Fragment>
                        {(
                          Math.round(
                            (numberOfProviders * providerPrice +
                              5 * numberOfProviders) *
                              100
                          ) / 100
                        ).toFixed(2)}
                      </React.Fragment>{" "}
                      <span>/ month</span>
                    </div>
                    <span className="t-price-description">
                      Total amount next billing
                    </span>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </Flex>
      <div className="t-note">
        {auth.get("subscriptionStatus") === "active" ? (
          <React.Fragment>
            {changed && (
              <React.Fragment>
                By changing your subscription, you’ll start a new billing month
                from today, and you’ll be billed on the <strong>{day}</strong>{" "}
                of the month going forwards. We’ll refund your existing plan and
                credit it on the first invoice for your new plan. If your credit
                is greater than the cost of your new plan, we’ll refund the
                difference. We’ll also charge you for any outstanding recording
                and archiving sessions or group sessions on your account at the
                same time as we do the plan change.
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            The payment information screen will only reference the on-going
            monthly cost of your subscription. We’ll charge you the one-time fee
            for your sessions as soon as your subscription is setup. Group
            session and recording session fees are paid monthly in arrears.
          </React.Fragment>
        )}
      </div>
      <div className="action-holder">
        {isAvailableForPlanPurchase() && type === "plan-selection" ? (
          <div>
            <button
              className={`btn-full btn-blue ${
                (hasValue || !isDomScanned) && "btn-disabled"
              }`}
              onClick={callScreenFunction}
            >
              {isDomScanned ? (
                "Continue to setup subscription"
              ) : (
                <div className="sweet-loading">
                  <PulseLoader
                    css={override}
                    size={12}
                    color={"#354e73"}
                    loading={true}
                  />
                </div>
              )}
            </button>
            {/* eslint-disable-next-line */}
            <a
              href="#!"
              data-billsby-type="checkout"
              data-billsby-product={process.env.REACT_APP_PRODUCTID}
              data-billsby-plan={
                recordingPlan
                  ? process.env.REACT_APP_PLANID_ON
                  : process.env.REACT_APP_PLANID_OFF
              }
              data-billsby-cycle={
                recordingPlan
                  ? process.env.REACT_APP_CYCLEID_ON
                  : process.env.REACT_APP_CYCLEID_OFF
              }
              data-billsby-units={numberOfProviders}
              data-billsby-allowances={addAllowance()}
              ref={checkOutref}
            />
          </div>
        ) : (
          <button
            className={`btn-full 
                ${
                  auth.get("subscriptionStatus") !== "active"
                    ? "btn-blue"
                    : changed
                    ? "btn-blue"
                    : "btn-manatee-blue btn-disabled"
                }`}
            type="button"
            onClick={
              auth.get("subscriptionStatus") === "active" &&
              type === "providers"
                ? handleUpdateProvider
                : handleSetupPlan
            }
          >
            {auth.get("subscriptionStatus") === "active" && type === "providers"
              ? "Confirm and update plan"
              : "Continue to setup subscription"}
          </button>
        )}
      </div>
    </div>
  );
};

export default ChoiceTotal;

import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'

import { GlobalContext } from '../context/GlobalContextProvider'
import Header from '../components/Header'
import CardBox from '../components/CardBox'
import Cancelling from '../components/Cancelling'

const headerInfo = {
  back: '/session',
  heading: 'Cancel subscription'
}

const CancelSubscription = () => {
  const { 
    cancelSubscription,
    handleContinueCancel
  }= useContext(GlobalContext)

  return (
    <div className="cancel-subscription-page">
      <Header headerInfo={headerInfo} />
      {!cancelSubscription ? (
        <div className="subscription-info-wrapper">
          <CardBox>
            <h4 className="t-card-title">Is there anything we can do to change your mind?</h4>
            <p className="t-card-desc">If there are any outstanding issues causing you to cancel your account, allow us to make it right. Please use the contact methods below to reach a support team member, and we will work to provide an expedited resolution.</p>
          </CardBox>
          <CardBox classNameVal="contact-info">
            <p className="t-email text-center"><FontAwesomeIcon icon={ faEnvelope } />Email us on <a href="mailto:support@sessioncare.com" target="_blank" rel="noreferrer">support@sessioncare.com</a></p>
            <p className="text-center"><FontAwesomeIcon icon={ faPhone } />Call us on <a href="tel:720-515-7384" target="_blank" rel="noreferrer">(720) 515-7384</a></p>
          </CardBox>
          <div className="action-holder text-center">
            <button type="button" className="btn-plain" onClick={handleContinueCancel}>Continue to cancel</button>
          </div>
        </div>
      ): (
        <Cancelling />
      )}
    </div>
  )
}

export default CancelSubscription
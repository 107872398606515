import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const NoticeList = ({ content }) => {
  return (
    <React.Fragment>
      <h3 className='t-border-title'>{content.title}</h3>
      <ul className='tools-list'>
        {content.list.map((sub_item, i) =>
          <li key={i} className='tool-item'><FontAwesomeIcon icon={sub_item.icon} /><p>{sub_item.text}</p></li>
        )}
      </ul>
      {content.status &&
        <div className='status'>
          <FontAwesomeIcon icon={faCheck} />
          <span>{content.status}</span>
        </div>
      }
    </React.Fragment>
  )
}

export default NoticeList; 

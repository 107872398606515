// Depedencies
import React from "react";
import { Route, Redirect } from "react-router-dom";

// Helpers
import auth from "./Auth";
// import { GlobalContext } from "../context/GlobalContextProvider";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  // const { createAlert } = useContext(GlobalContext);

  return (
    <React.Fragment>
      <Route
        {...rest}
        render={(props) => {
          if (!auth.isAuthenticated()) {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            if (auth.get("authenticated") === "true") {
              return <Component {...props} />;
            }
          }
        }}
      />
    </React.Fragment>
  );
};

export default ProtectedRoute;

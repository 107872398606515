import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { GlobalContext } from "../context/GlobalContextProvider";
import Header from "../components/Header";
import CardBox from "../components/CardBox";
import Circle from "../components/Circle";
import Toggle from "../components/Toggle";

const sessionMessage = {
  sessionVal: "10,000",
};

const headerInfo = {
  back: "/session",
  heading: "Manage auto session refill",
};

const SessionRefill = () => {
  const { toggle, customerDetails, handleUpdateAutoRefill } = useContext(
    GlobalContext
  );

  const [sessionPerAutoTopup, setSessionPerAutoTopup] = useState(0);
  const [sessionAutoTopupTrigger, setSessionAutoTopupTrigger] = useState(0);
  const [changedPreference, setChangePreference] = useState(false);

  const handleOnChange = (e) => {
    let value = parseInt(e.target.value);

    console.log(value);
    if (isNaN(value)) value = 0;

    if (e.target.name === "sessions_per_auto_topup")
      setSessionPerAutoTopup(value);
    else if (e.target.name === "auto_topup_trigger")
      setSessionAutoTopupTrigger(value);
    else return;
  };

  useEffect(() => {
    setSessionPerAutoTopup(customerDetails.sessions_per_auto_topup);
    setSessionAutoTopupTrigger(customerDetails.auto_topup_trigger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails]);

  useEffect(() => {
    if (
      toggle !== customerDetails.sessions_auto_topup_status ||
      sessionAutoTopupTrigger !== customerDetails.auto_topup_trigger ||
      sessionPerAutoTopup !== customerDetails.sessions_per_auto_topup
    )
      setChangePreference(true);
    else setChangePreference(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, sessionAutoTopupTrigger, sessionPerAutoTopup]);

  return (
    <React.Fragment>
      <Header headerInfo={headerInfo} />
      <div div className="session-refill-page">
        <CardBox classNameVal="add-session-info">
          <Circle circleContent={sessionMessage} />
          <p className="t-card-desc text-center">
            Sessions are unique encounters conducted via secure videos, voice,
            and chat. The session balance is shared among all providers within
            your account. Each session includes up to 20 minutes of live video
            and voice interaction for up to three participants. Unused sessions
            rollover and do not expire.
          </p>
        </CardBox>
        <CardBox classNameVal="session-refill">
          <h4 className="t-card-title">Auto session refill preferences</h4>
          <Toggle />
          {toggle && (
            <p>
              Add
              <input
                placeholder="5000"
                value={sessionPerAutoTopup}
                name="sessions_per_auto_topup"
                onChange={handleOnChange}
              />
              more sessions to my account at the{" "}
              <Link to="/add-session" className="btn-plain">
                prevailing rate
              </Link>{" "}
              every time my balance falls below
              <input
                placeholder="20,000"
                value={sessionAutoTopupTrigger}
                name="auto_topup_trigger"
                onChange={handleOnChange}
              />
            </p>
          )}
        </CardBox>
        {toggle && (
          <div className="note-holder">
            <p className="t-refill-note text-center">
              Auto session refill checks your balance daily. We recommend that
              your minimum session balance be at least two days worth of average
              session usage.
            </p>
          </div>
        )}
        <div className="action-holder">
          <button
            className={
              changedPreference
                ? "btn-blue btn-full"
                : "btn-manatee-blue btn-disabled btn-full"
            }
            onClick={() =>
              handleUpdateAutoRefill(
                sessionPerAutoTopup,
                sessionAutoTopupTrigger,
                toggle
              )
            }
          >
            Update refill preference
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SessionRefill;

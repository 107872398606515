import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => (
  <div className="not-found-page">
    <h2 className="heading text-center">Page Not Found</h2>
    <p className="sub-heading text-center">
      Opps! The page you are looking for is not available or relocated
    </p>
    <Link to="/plan-selections" className="btn-plain">
      Go Back to Home
    </Link>
  </div>
);

export default NotFoundPage;

import React, { useContext, useEffect } from "react";

import Alert from "../components/Alert";
import { GlobalContext } from "../context/GlobalContextProvider";

const Cancellation = () => {
  const { alertStatus } = useContext(GlobalContext);

  useEffect(() => {
    if (alertStatus.length === 0) window.location.replace("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertStatus]);

  return (
    <React.Fragment>
      <Alert />
    </React.Fragment>
  );
};

export default Cancellation;

import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";

const QuantityCounterProviders = () => {
  const {
    changed,
    setChanged,
    numberOfProviders,
    setNumberOfProviders,
    location,
    createAlert,
    alertStatus,
  } = useContext(GlobalContext);

  const [providerLimit, setProviderLimit] = useState(null);

  const createError = () => {
    let errorMsg = `The number of providers currently provisioned in your account (${providerLimit}) exceeds the selected quantity. Please increase the number of providers below, or deactivate providers from the Mange Providers page of the administrator panel`;
    if (alertStatus.length > 0) {
      console.log(errorMsg);
      if (alertStatus[0].title !== errorMsg) createAlert("error", errorMsg, "");
    } else createAlert("error", errorMsg, "");
  };

  const handleQuantityCounter = (e) => {
    const value = e.target.value;
    if (value < 1) {
      if (providerLimit !== null) {
        // Set Error message
        createError();
        setNumberOfProviders(providerLimit);
        return;
      } else {
        setNumberOfProviders(1);
      }
    } else {
      if (providerLimit !== null) {
        if (providerLimit >= value) {
          // Set Error message
          createError();
          setNumberOfProviders(providerLimit);
          return;
        } else {
          setNumberOfProviders(parseInt(value));
        }
      } else {
        setNumberOfProviders(parseInt(value));
      }
    }
  };

  const decrease = () => {
    if (providerLimit !== null) {
      if (providerLimit >= numberOfProviders) {
        // Set Error message
        createError();
        return;
      }
    }
    setNumberOfProviders(numberOfProviders - 1);

    if (!changed) {
      setChanged(!changed);
    }
  };
  const increase = () => {
    setNumberOfProviders(numberOfProviders + 1);

    if (!changed) {
      setChanged(!changed);
    }
  };

  useEffect(() => {
    const minproviders = new URLSearchParams(location.search).get(
      "minproviders"
    );
    if (minproviders !== null) {
      setProviderLimit(parseInt(minproviders));
      sessionStorage.setItem("minproviders", minproviders);
    } else {
      if (sessionStorage.getItem("minproviders")) {
        console.log(
          "Set minproviders to " + sessionStorage.getItem("minproviders")
        );
        var newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?minproviders=${sessionStorage.getItem("minproviders")}`;
        window.history.replaceState({ path: newurl }, "", newurl);

        // Reload page to make the query take effect
        window.location.reload();
      } else {
        console.log("no min providers in URL");
      }
    }

    // eslint-disable-next-line
  }, []);

  /* -------------------------------------------------------------------------- */
  /*               Sets minproviders URL Param during Free trials               */
  /* -------------------------------------------------------------------------- */

  // useEffect(() => {
  //   /**
  //    * IF status is free trial, this inserts a minprovider URL param in the database
  //    */
  //   if (Auth.get("subscriptionStatus") === "freetrial")
  //     if (customerDetails.number_of_providers !== undefined) {
  //       const minproviders = new URLSearchParams(location.search).get(
  //         "minproviders"
  //       );
  //       /**
  //        * If minproviders does not exist in URL param
  //        */
  //       if (minproviders === null) {
  //         console.log(
  //           "Set minproviders to " + customerDetails.number_of_providers
  //         );
  //         var newurl =
  //           window.location.protocol +
  //           "//" +
  //           window.location.host +
  //           window.location.pathname +
  //           `?minproviders=${customerDetails.number_of_providers}`;
  //         window.history.replaceState({ path: newurl }, "", newurl);

  //         // Reload page to make the query take effect
  //         window.location.reload();
  //       }
  //       else {
  //         /**
  //          * Auto corrects URL Param if minproviders is NOT EQUAL to number of providers in database
  //          */
  //         if (parseInt(minproviders) !== customerDetails.number_of_providers) {
  //           console.log(
  //             "Correcting minproviders to " +
  //               customerDetails.number_of_providers
  //           );
  //           var newurl =
  //             window.location.protocol +
  //             "//" +
  //             window.location.host +
  //             window.location.pathname +
  //             `?minproviders=${customerDetails.number_of_providers}`;
  //           window.history.replaceState({ path: newurl }, "", newurl);

  //           // Reload page to make the query take effect
  //           window.location.reload();
  //         }
  //       }
  //     }
  // }, [customerDetails]);

  return (
    <div className="def-number-input number-input">
      <button
        onClick={decrease}
        className={`minus qty-button ${
          numberOfProviders === 1 && "btn-disabled"
        }`}
      >
        -
      </button>
      <input
        className="quantity"
        name="quantity"
        type="number"
        value={numberOfProviders}
        onChange={handleQuantityCounter}
      />
      <button onClick={increase} className="plus qty-button">
        +
      </button>
    </div>
  );
};

export default QuantityCounterProviders;

import React from "react";
import { Flex } from "rebass";
import { Link } from "react-router-dom";

import Circle from "../components/Circle";

const Sessions = () => (
  <div className="session-holder">
    <h4 className="t-card-title text-left">Your Sessions</h4>
    <Flex flexWrap="wrap" justifyContent="space-between" alignItems="center">
      <Circle />
      <div className="action-holder">
        <Link to="/add-session" className="btn-blue">
          Add more sessions
        </Link>
        <Link to="/session-refill" className="btn-transparent">
          Manage auto session refill
        </Link>
        {/* <a href="/add-session" className="btn-blue">
          Add more sessions
        </a> */}
        {/* <a href="/session-refill" className="btn-transparent">
          Manage auto session refill
        </a> */}
      </div>
    </Flex>
  </div>
);

export default Sessions;
